
import React, { Component, useState, useContext } from "react";
import { Drawer, List, message, Row, Col, Typography, Divider } from "antd";
import { Button } from "antd";
import axios from "axios";
import { User, SetUser } from "../../../Hooks/Logged";

import CardItem from "./CartItem"
import StepPayment from "./StepPayment";
import StepResumen from "./StepResumen";

import '../../../Styles/Modules/cart.scss'

class Cart extends Component {
    constructor(props) {
        super(props);
    }


    /**
     *
     *
     * @memberof Cart
     */
    deleteItem = (id) => {

        axios.delete('/carrito', {
            params: {
                venta_id: this.props.cookies.cart,
                detalle_id: id
            }
        }).then(({ data }) => {
            console.log('se trajo carrito', data)
            this.props.setCart(data)
        }).catch((error) => {
            console.log(error)
        })
    }


    render() {
        return <>
            <List
                className="cart"
                dataSource={Array.isArray(this.props.cart?.detalles) ? this.props.cart?.detalles : []}
                locale={{ emptyText: "The cart is empty" }}
                header={
                    <>
                        <Row align={"center"} justify={"space-between"}>
                            <Col span={15} >Product/Services</Col>
                            <Col span={4}  >Cost</Col>
                            <Col span={1} className="center">Qty</Col>
                            <Col span={4}  ></Col>
                        </Row>
                    </>
                }
                footer={
                    <>
                        <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Subtotal</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart.subtotal ?? 0).toMoney(true)}</Col>
                        </Row>
                        <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Taxes</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart.tax ?? 0).toMoney(true)}</Col>
                        </Row>
                        <Row align={"center"} >
                            <Col span={6} className="cart-resume-text">Total</Col>
                            <Col span={18} className="cart-resume-amount">{(this.props.cart.subtotal ?? 0 + this.props.cart.tax ?? 0).toMoney(true)}</Col>
                        </Row>
                    </>
                }
                renderItem={item => <List.Item>
                    <CardItem item={item} cantidad={item.cantidad} onDelete={this.deleteItem} />
                </List.Item>}
            />
        </>
    }
}



export default function DrawerCarrito(props) {
    let user = useContext(User)
    const [step, setStep] = useState(0);

    let next = () => {
        setStep(step + 1)
    }
   


    return <Drawer
        className="cart"
        open={props.open}
        onClose={props.close}
        destroyOnClose={true}
        title="Cart"
        extra={<Button type="primary" onClick={() => next()}>Pay</Button>}
        width={500}
    >
        {step === 0 && <Cart {...props} next={next} />}
        {step === 1 && <StepResumen {...props} next={next} user={user} />}
        {step === 2 && <StepPayment {...props} next={next} user={user} />}

    </Drawer>

}