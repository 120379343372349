
import React, { useState } from "react";
import { Avatar } from "antd";

import './Avatar.css';


const renderUrl = ({ url, name, logo, avatar }) => {
    const urlParams = new URL(url)
    urlParams.searchParams.set("Authorization", sessionStorage.getItem("token"))
    if (name) urlParams.searchParams.set("name", name)
    if (logo) urlParams.searchParams.set("logo", logo)
    if (avatar) urlParams.searchParams.set("avatar", avatar)

    return urlParams.href
}



export default function CustomAvatar({ image, name = "", color = "#EE492A", size = "default", style = {}, className = "", url = null }) {
    let [error, setError] = useState(true);

    if ((image !== undefined && image !== null && image !== "" && error) || url?.name || url?.logo) {
        return (
            <Avatar
                style={{
                    marginRight: '5px',
                    backgroundColor: color,
                    ...style,
                }}
                size={size}
                src={url ? renderUrl(url) : image}
                onError={() => setError(false)}
            />
        )
    } else {
        let title = "";
        if (Array.isArray(name) && name.length > 1) {
            title += name[0] ? name[0].charAt(0) : 'N';
            title += name[1] ? name[1].charAt(0) : 'N';
        } else {
            const words = ((typeof name == "string") ? name : "").split(" ");
            for (const [index, word] of words.entries()) {
                if (index > 1) break;
                title += word.charAt(0);
            }
        }


        return (
            <Avatar
                className={className}
                style={{
                    backgroundColor: color,
                    marginRight: '5px',
                    ...style,
                }}
                size={size}

            >
                {title.toUpperCase()}
            </Avatar>
        )
    }


}


