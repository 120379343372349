import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Image, Alert, Layout } from 'antd';
import '../../Styles/Modules/Landing/landing.scss';

// Components
import { Link } from 'react-router-dom';

const { Title } = Typography
const { Header, Content } = Layout

/**
 * 
 * @class Landing
 * @extends {Component}
 * @description Componente Landing page
 */
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  render() {
    return(
      <Layout className="landing">
        <Header className="landing-header">
            <Row justify={"space-between"} align={"middle"}>
                <img src="/img/logo-white.svg" alt="Logo" className='header-logo' />
                <Link className="header-login" to='/login' >
                    <Title level={3}>LOGIN</Title>
                </Link>
            </Row>
        </Header>
        <Content className="landing-content">
            <Row gutter={[24, 24]} className='content-container'>
                <Col span={24}>
                    <Button type="primary" size="large" block className='content-erp'>Enterprise Resource Planning</Button>
                </Col>
                <Col span={24}>
                    <img src="/img/logo-border.svg" alt="Logo" className='content-logo'/>
                </Col>
            </Row>
        </Content>
      </Layout>
    )
  }
}

export default function (props) {

  return <Landing {...props} />
}