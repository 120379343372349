import React, { Component } from 'react';
import {
    Row, Col, Typography, Button, Modal,
    Layout, Space, message, List, Card, Dropdown, Tag, Menu, Select, Checkbox, Tooltip, Input, Radio
} from 'antd'
import { Link, useNavigate } from "react-router-dom";
import axios, { } from 'axios';
import { UnorderedListOutlined, MenuOutlined } from '@ant-design/icons';

import User from '../../../Hooks/Logged'
import usePermissions from '../../../Hooks/usePermissions';
import useSearch from '../../../Hooks/useSearch';
import PageHeader from '../../Widgets/PageHeader/PageHeader';

import '../../../Styles/Modules/Admin/ventas.scss'
import VentasList from './VentasList';
import VentasTab from './VentasTab';

const { Content } = Layout;
const { Text, Title } = Typography
const { Option } = Select
const { confirm } = Modal


/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class Ventas extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        //let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,
            view: 'tabs'
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.props.setShowSearch(true)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
    }


    render() {
        const { view } = this.state
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<>
                        Ventas
                    </>}
                    extra={<Space>
                        <Radio.Group
                            options={[
                                { label: <MenuOutlined />, value: "list" },
                                { label: <UnorderedListOutlined />, value: 'tabs' },
                            ]}
                            value={this.state.view}
                            onChange={(e) => this.setState({view: e.target.value})}
                            optionType="button"
                            buttonStyle="solid"
                        />
                    </Space>}
                />
                {
                    view === 'tabs' ?  <VentasTab/> : <VentasList/>
                }
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {})

    const navigate = useNavigate();


    return <Ventas {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}