import React from 'react';
import { Card, Row, Col, Typography, Button, Space,  } from 'antd'
import axios from 'axios';
import '../../Styles/Global/cards.css'
import './cards.css'
import { ButtonDelete,  ButtonEdit, } from "./Buttons"
import { FaArrowRight } from 'react-icons/fa'
import { FiArrowDownRight, FiArrowUpRight } from 'react-icons/fi'


const { Text, Title, Paragraph } = Typography;


/**
 *
 *
 * @const CardStatistics
 * @extends {React.Component}
 * @description Card para estadisticas del dashboard principal
 */
const CardStatistics = ({ monto = 0, icon, color = "#EE492A", title, suffix, onClick, percent }) => {

    let aumento = percent !== undefined && !(percent < 0)

    return <Card size="small" className="card-statistics">
        <Row align={"middle"} className="card-header">
            <Col flex="auto">
                <Text className="card-title">{title}</Text>
            </Col>
            <Col flex="none">
                {
                    onClick && (
                        <Button
                            type="primary"
                            className='btn-primary'
                            icon={<FaArrowRight />}
                            onClick={onClick}
                        />
                    )
                }
            </Col>
        </Row>
        <Row align={"middle"} className="card-content">
            <Col flex="auto">
                <Space size={2}>
                    <Text className="card-monto">{monto}</Text>
                    <Text className="card-suffix">{suffix}</Text>
                </Space>
            </Col>
            <Col flex="none" className="center">
                {
                    icon && (
                        <div
                            className="icon-container"
                            style={{
                                backgroundColor: color + "B2"
                            }}
                        >
                            {
                                typeof icon === 'function' ? icon() : icon
                            }
                        </div>
                    )
                }
            </Col>
        </Row>
        {
            percent !== undefined && (
                <div className={`card-percent ${aumento ? "green" : "red"}`}>
                    <Space align='center'>
                        {
                            aumento ? <FiArrowUpRight className='card-icon' color='#00CD98' /> : <FiArrowDownRight className='card-icon' color='#FF4D4F' />
                        }
                        <Text className="card-monto">{aumento ? "+" : "-"} {Math.abs(percent)}%</Text>
                    </Space>
                </div>
            )
        }
    </Card>
}



/**
 * @const CardSimpleInfo
 * @description Card para mostrar informacion simple o numerica
 * @param {*} step Object con la información del step
 * @param String color Color del step
 */
const CardSimpleInfo = ({ title = "Profit", toolTipText = 'success', info = 0 , content}) => {
	return <Card className="card-shadow card-simple">
		<Row>
			<Col span={24} className="flex-left">
				<Text className="title-simple">{title}</Text>
			</Col>
			<Col span={24} className="" >
				{ content ?? <Paragraph className="info-simple mb-0">{info}</Paragraph> }
			</Col>
		</Row>
	</Card>
}

const CardAddress = ({item, onEdit, onDelete, disabledEdit, disabledDelete}) => {

    let place = [
        `${item.direccion}`,
        `${item.codigo_postal}, ${item.ciudad}`,
        `${item.estado}, ${item.pais}`
    ]

    return (
        <Card title={item.nombre} className='card-direcciones' extra={<Space>
            <ButtonEdit onClick={onEdit} disabled={disabledEdit}/>
            <ButtonDelete
                disabled={disabledDelete}
                onConfirm={onDelete}
            />
        </Space>}>
            <Space direction='vertical' size={5}>
                {
                    place.map((p, i) => (<Text key={`${item._id}-place-${i}`}>{p}</Text>))
                }
                <Paragraph className='text-gray card-direcciones-descripcion' ellipsis={{
                    rows: 3
                }}>
                    {item.notas}
                </Paragraph>
            </Space>
        </Card>
    )
}

export {
    CardStatistics,
    CardSimpleInfo,
    CardAddress
}