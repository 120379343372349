import { Breadcrumb, Card, Col, Layout, Row, Space, Statistic, Tabs, Typography, message } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//componentes
import usePermissions from "../../../Hooks/usePermissions";
import { CardSimpleInfo } from '../../Widgets/Cards';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import ProductosVentas from './Tabs/ProductosVentas';
import { FlagFilled, PaperClipOutlined } from '@ant-design/icons';


import '../../../Styles/Modules/Admin/ventas.scss'
import dayjs from 'dayjs';
import User from '../../../Hooks/Logged';

const { Content } = Layout;
const { Title, Text, Link, Paragraph } = Typography
const moment = require('moment')
moment.locale('es');



/**
 *
 *
 * @export
 * @class DetalleVentas
 * @extends {Component}
 * @description Vista donde se muestra la informacion de un venta
 */
class DetalleVentas extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            venta_id: this.props.params.venta_id,
            venta: {

            },
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.params.venta_id) {
            this.getVenta()
        }
    }


    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getVenta
     * @description Obtiene informacion del Venta
     */
    getVenta = () => {
        this.setState({ loading: true })
        axios.get('/venta/' + this.state.venta_id, )
            .then(response => {
                this.setState({
                    venta: response.data
                })

            }).catch(error => {
                console.log("error", error);
                message.error("Error al obtener la informacion del catálogo ")
            }).finally(() => this.setState({ loading: false }))
    }


    render() {

        const { venta } = this.state

        const items = []
        if(this.props.verDetalles) items.push({
            key: 'productos',
            label: 'Detalles',
            children: <ProductosVentas getData={() => this.getVenta()} venta_id={this.props.params.venta_id} />,
        })

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<Breadcrumb
                        className="breadcrumb-header"
                        items={[
                            {
                                title: "Ventas",
                                onClick: () => this.props.navigate('/admin/ventas'),
                                className: "link"
                            },
                            {
                                title: ` ${venta?.order_id}`,
                            },
                        ]}
                    />}
                >
                </PageHeader>
                <Content className="pd-1 venta">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <Card className='venta-info'>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Text className='info-order'>{venta.order_id}</Text>
                                    </Col>
                                    <Col span={24} className="info-date">
                                        <Space>
                                            <FlagFilled/>
                                            <Text>Creado el {dayjs(venta.createdAt).format('DD/MM/YYYY')}</Text>
                                        </Space>
                                    </Col>
                                    <Col span={24}>
                                        <Text>{venta.cliente_id?.nombre} {venta.cliente_id?.apellidos}</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Text>{venta.cliente_id?.telefono}</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Text>{venta.cliente_id?.email}</Text>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <Card className='venta-info'>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Text className='info-title'>Total Venta</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Statistic
                                            className='info-money'
                                            prefix="$"
                                            value={venta.subtotal}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <Card className='venta-info'>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div className='info-tag'><Text>Dirección</Text></div>
                                    </Col>
                                    <Col span={24}>
                                        <Space direction='vertical' size={2}>
                                            <Text>{venta.direccion?.direccion}</Text>
                                            <Text>CP. {venta.direccion?.codigo_postal ?? '--'}, {venta.direccion?.ciudad ?? '--'}</Text>
                                            <Text>{venta.direccion?.estado ?? '--'}, {venta.direccion?.pais ?? '--'}</Text>
                                        </Space>
                                    </Col>
                                    <Col span={24}>
                                        <Paragraph>
                                            {venta.direccion?.notas}
                                        </Paragraph>
                                        <Paragraph>
                                            {venta.direccion?.especificaciones}
                                        </Paragraph>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Tabs
                        defaultActiveKey="productos"
                        destroyInactiveTabPane={true}
                        items={items}
                    />

                </Content>
            </>
        )
    }
}


export default function (props) {

    const params = useParams()
    const navigate = useNavigate()

    
    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        verDetalles: ["ventas", "productos-ventas", "access"]
    })


    return <DetalleVentas  {...props} params={params} navigate={navigate} rol={user?.rol_id?.permisos}
        {...permisos}
    />
}