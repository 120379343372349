import React, { Component, } from 'react';
import { Row, Col, Layout, Card, List, Typography, Tag, Space, Form, Button, Progress, Input, Image } from 'antd'
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSearch from '../../../Hooks/useSearch';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { ButtonView } from '../../Widgets/Buttons';
import { CheckOutlined, EditFilled } from '@ant-design/icons';

import '../../../Styles/Modules/Customer/Quotations.scss'

const { Content } = Layout;
const { Text } = Typography;

/**
 *
 *
 * @export
 * @class Quotation
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Quotation extends Component {

    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            address: {},
            editable: false
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
    }

    componentWillUnmount(){
        this.props.setShowSearch(true)
    }


    renderTransaccion = (item) => {
        return (
            <List.Item className="dashboard-transactions-item">
                <Row className="width-100" gutter={[6,8]}>
                    <Col span={3}>
                        <Text strong>{"{{Fecha}}"}</Text>
                    </Col>
                    <Col span={7}>
                        <Text>{"{{Concepto}}"}</Text>
                    </Col>
                    <Col span={6}>
                        <Text>{"{{Cuenta}}"}</Text>
                    </Col>
                    <Col span={5} className="flex-right">
                        <Text>{"{{Monto}}"}</Text>
                    </Col>
                    <Col span={3} className="flex-right">
                        <ButtonView
                            title="View Details"
                            onClick={() => { }}
                        />
                    </Col>
                </Row>
            </List.Item>
        )
    }

    onChangeAddress = (key, value) => {
        this.setState(state => {
            state.address[key] = value
            return state
        })
    }

    renderFiles = (item) => {
        return (
            <List.Item className="quotations-files-item">
                <Row className="width-100" gutter={[6,8]} align={"middle"}>
                    <Col xs={10} lg={2}>
                        <Image
                            placeholder
                            className='item-image'
                        />
                    </Col>
                    <Col xs={14} lg={22}>
                        <Space size={4} direction='vertical'>
                            <Text strong>Descripción</Text>
                            <Text ellipsis>{"{{Descripcion}}"}</Text>
                        </Space>
                    </Col>
                </Row>
            </List.Item>
        )
    }


    render() {

        const {address} = this.state

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Quotation"
                    subTitle="#00000"
                />
                <Content className="customer-content quotations-details content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                            <Card className='quotations-card'>
                                <Row gutter={[0, 10]}>
                                    <Col span={12}>
                                        <Text strong>Quotation</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text>#0000</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text strong>Estatus</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Tag>Estatus</Tag>
                                    </Col>
                                    <Col span={12}>
                                        <Text strong>Date</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text>{"{{Fecha}}"}</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text strong>Cost</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text>{"{{Costo}}"}</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text strong>Services Type</Text>
                                    </Col>
                                    <Col span={12}>
                                        <Text>{"{{Servicio}}"}</Text>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card className="quotations-card quotations-address">
                                <Row justify={"space-between"} align={"middle"}>
                                    <Text strong>Delivered Address</Text>
                                    {
                                        this.state.editable ? (
                                            <Button
                                                icon={<CheckOutlined/>}
                                                type="primary"
                                                size='small'
                                                onClick={() => {
                                                    //this.updateAddress()
                                                    this.setState({editable: false})
                                                }}
                                            />
                                        ) : (
                                            <Button
                                                icon={<EditFilled/>}
                                                type="primary"
                                                size='small'
                                                onClick={() => {
                                                    this.setState({editable: true})
                                                }}
                                            />
                                        )
                                    }
                                </Row>
                                <Row className='quotations-address-form' gutter={[0, 5]}>
                                    <Col span={24}>
                                        <Text className={address?.direccion1 ? '' : 'text-gray'}>Address 1</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Text className={address?.direccion2 ? '' : 'text-gray'}>Address 2</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Text className={address?.codigo_postal ? '' : 'text-gray'}>CodePost</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Text className={address?.ciudad ? '' : 'text-gray'}>City</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Text className={address?.estado ? '' : 'text-gray'}>State</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Text className={address?.pais ? '' : 'text-gray'}>Country</Text>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} lg={8}>
                            <Card className='quotations-card'>
                                <Row>
                                    <Space size={8} direction='vertical' className='width-100'>
                                        <Text strong>Progress Quotation</Text>
                                        <Progress strokeColor={"#D459E8"} percent={60}/>
                                    </Space>
                                </Row>
                                <Row>
                                    <Space size={8} direction='vertical' className='width-100'>
                                        <Text strong>Payment</Text>
                                        <Text className='text-gray'>Credit Card **** ####</Text>
                                    </Space>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24}>
                            <Card title={'Files Uploaded'}>
                                <List
                                    loading={this.state.loading}
                                    className="quotations-files-list"
                                    itemLayout="horizontal"
                                    locale={{ emptyText: "Files sin registros" }}
                                    dataSource={[{}, {}, {}, {}, {}]}
                                    renderItem={item => this.renderFiles(item)}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();



    return <Quotation {...props} setShowSearch={setShow}  navigate={navigate} params={params} />
}
