import React, { useState, useEffect, } from 'react';
import { Badge, Button, Layout, Menu, Space, Row, Col } from 'antd';
import { UserOutlined, ShoppingOutlined } from '@ant-design/icons';
import DrawerCarrito from './Carrito/Drawer';
import axios from "axios";

const { Header } = Layout;

/**
 * @const Header header publico de la landing page
 * @param {*} props 
 * @returns Component
 */
const MyHeader = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    const items = [
        {
            label: 'sneacare',
            key: 'logo',
        },
        {
            label: 'New Release',
            key: 'release',
        },
        {
            label: 'Best Seller Two',
            key: 'best_seller',
        },
        {
            label: 'Shop',
            key: 'shop',

        },
        {
            label: 'Blog',
            key: 'blog',

        },
        {
            label: 'Testimonial',
            key: 'testimonial',

        },
        {
            label: 'Contact',
            key: 'contact',

        },
    ];
    useEffect(() => {
        if (props.cookies.cart) {
            axios.get('/carrito/' + props.cookies.cart, {
            }).then(({ data }) => {
                console.log('data c', data)
                props.setCart(data)
            }).catch((error) => {
                console.log(error)
            })
        }
    }, [])
    const setVisible = () => {
        setIsVisible(!isVisible);
    };

    return (
        <>
            <Header className='header-public'>
                <Menu mode="horizontal" items={items} className='width-100 ' rootClassName='menu-landing' />
                <Row  justify={"end"}>
                    <Col span={12} >
                        <Button type="link" icon={<UserOutlined />} onClick={() => props.navigate('/login')} ></Button>
                    </Col>
                    <Col span={12} >
                        <Badge count={props.cart?.detalles?.length}>
                            <Button type="link" onClick={setVisible} icon={<ShoppingOutlined />}></Button>
                        </Badge>
                    </Col>
                </Row>

            </Header>

            {isVisible && (
                <DrawerCarrito
                    open={isVisible}
                    close={setVisible}
                    {...props}
                />
            )}
        </>
    );
};

export default MyHeader;