import React, { Component, } from 'react';
import { Row, Col, Layout, Card, message } from 'antd'
import ReactECharts from 'echarts-for-react';
import axios from 'axios';
import { IconWallet, IconGraphic, IconCuenta, IconShield } from '../../Widgets/Iconos'
import { CardStatistics } from '../../Widgets/Cards'
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSearch from '../../../Hooks/useSearch';
import PageHeader from '../../Widgets/PageHeader/PageHeader';

const { Content } = Layout;

/**
 *
 *
 * @export
 * @class Dashboard
 * @extends {Component}
 * @description Vista donde se muestra un resumen de las estadisticas del sistema
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            plantas: {
                nombres: [],
                disponibles: [],
                ocupados: []
            },
            montos: {
                totales: 0,
                mes: 0,
                invertido: 0,
                confirmar: 0

            },
            ventas: {
                mensual: [],
                anual: []
            }
        }
    }


    componentDidMount() {
        this.props.setShowSearch(false)
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.get()
    }

    get = () => {
        this.setState({loading: true})
        axios.get('/dashboard')
            .then(({data}) => {
              this.setState({...data})  
            })
            .catch(error => {
                message.error("Error al obtener los datos")
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }

    render() {

        const { montos, ventas, loading } = this.state


        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="Dashboard"
                    // subTitle="subtitle"
                />
                <Content className="admin-content content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={12} xl={6} className="grid">
                            <CardStatistics title="Ventas Totales" monto={montos?.ventas} icon={(color) => <IconWallet color={color} />} />
                        </Col>
                        <Col xs={24} sm={12} md={12} xl={6} className="grid">
                            <CardStatistics title="Cotizaciones Totales" monto={montos?.cotizaciones} icon={(color) => <IconGraphic color={color} />} />
                        </Col>
                        <Col xs={24} sm={12} md={12} xl={6} className="grid">
                            <CardStatistics title="Productos Totales" monto={montos?.productos} icon={(color) => <IconCuenta color={color} />} />
                        </Col>
                        <Col xs={24} sm={12} md={12} xl={6} className="grid">
                            <CardStatistics title="Clientes totales " monto={montos?.clientes} icon={(color) => <IconShield color={color} />} />
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Card title={'Ventas por Mes'} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={{
                                                title: {
                                                  text: ''
                                                },
                                                tooltip: {},

                                                xAxis: {
                                                  data: ventas.mensual?.map(v => `${v.month}/${v.year}`)
                                                },
                                                yAxis: {},
                                                series: [{
                                                    name: 'Ventas',
                                                    type: 'bar',
                                                    data: ventas.mensual?.map(v => v.total)
                                                }]
                                            }}
                                            style={{ width: '100%', maxWidth: 400 }}
                                            opts={{ renderer: 'svg' }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Card title={'Ventas Anuales'} style={{ boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1)' }}>
                                <Row>
                                    <Col span={24} className="center">
                                        <ReactECharts
                                            option={{
                                                title: {
                                                  text: ''
                                                },
                                                tooltip: {},

                                                xAxis: {
                                                  data: ventas.anual?.map(v => `${v.year}`)
                                                },
                                                yAxis: {},
                                                series: [{
                                                    name: 'Ventas',
                                                    type: 'bar',
                                                    data: ventas.anual?.map(v => v.total)
                                                }]
                                            }}
                                            style={{ width: '100%', maxWidth: 700 }}
                                            opts={{ renderer: 'svg' }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                    </Row>
                </Content>

            </>
        )
    }
}

export default props => {
    const { setShow } = useSearch()
    const navigate = useNavigate()
    const [params, setParams] = useSearchParams();



    return <Dashboard {...props} setShowSearch={setShow}  navigate={navigate} params={params} />
}
