import React, { Component } from "react";
import { Row, Col, Space, Modal, List, Upload, Button, Typography, Form, DatePicker, Input, InputNumber, Select, message, Spin, Cascader, Tag, AutoComplete, Divider } from 'antd';


import { FaFileCsv } from "react-icons/fa";

import { IconTemplate } from "../../Widgets/Iconos";


import axios from "axios"
const { Title, Text, Paragraph } = Typography;

/**
 *
 *
 * @class ModalCSV
 * @extends {Component}
 * @description Agregar multiples transacciones 
 */
class ModalCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }


    /**
     *
     *
     * @param {*} info
     * @memberof ModalCSV
     * @description Cuando el archivo se sube o hay un error, queda almacenado aquí
     */
    onChangeUpload = (info) => {
        const { status } = info.file;
        if (status === 'done' || status === 'error') {
            if (status === 'done')
                Modal.success({
                    title: "El archivo ha sido cargado exitosamente.",
                    content: "Los productos han sido creados",
                    onOk: (...event) => {
                        this.props.onSuccess(true)
                    }
                })
            else 
                Modal.warning({
                    title: "El archivo ha sido cargado con algunos errores.",
                    content: "Algunos producto no ha sido creados, revise el documento.",
                    okText: "Cerrar",
                    onOk: () => {
                        this.props.onSuccess(false)
                    }
                })
        } 
    }

    render() {


        return (
            <Form
                layout="vertical"

                name="form-productos"
                id="form-productos"
                ref={this.modalRef}
                onFinish={this.onFinish}
            >
                <Spin spinning={this.state.loading}>
                    <Title className="mb-2 mt-1" level={5} style={{ textAlign: "center" }}>Considera las siguientes indicaciones:</Title>
                    <List
                        bordered={false}
                        size="small"
                        dataSource={[
                            "Revisa que los datos sean correctos y correspondan al campo.",
                            "Descarga el template .csv para subir varios productos.",
                            "Los campos \"ACTIVO\" y \"HOT\" solo aceptan SI/NO como respuesta.",
                        ]}
                        renderItem={item => <List.Item>{item}</List.Item>}
                    />
                    <div style={{ marginTop: "2em", textAlign: "center" }}>
                        <a download href="/files/template_productos.csv"> 

                            <IconTemplate />
                        </a>
                    </div>

                    <Row style={{ width: "100%" }} className="mt-2 mb-2">
                        <Upload.Dragger
                            className="width-100"
                            {...{
                                name: 'file',
                                multiple: false,
                                maxCount: 1,
                                action: axios.defaults.baseURL + "/productos/csv",
                                headers: {
                                    Authorization: sessionStorage.getItem("token")
                                },
                                onChange: this.onChangeUpload
                            }}>
                            <div style={{ margin: "0 1em" }}>
                                <p className="ant-upload-drag-icon">
                                    <FaFileCsv style={{ fontSize: "3em" }} />
                                </p>
                                <p className="ant-upload-text">Selecciona o arrastra el documento .csv en este espacio para subirlo</p>
                                <p className="ant-upload-hint">Solo es posible cargar archivos .csv</p>
                            </div>
                        </Upload.Dragger>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id, clasificacion } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Agregar Multiples Productos"}
        closable={true}
        maskClosable={true}
        destroyOnClose={true}
        zIndex={1000}
        okText="Agregar Productos"
        cancelText="Cancelar"
        okButtonProps={{ form: 'form-transaccion', key: 'submit', htmlType: 'submit' }}
    >
        <ModalCSV {...props} />
    </Modal>

}