import React, { Component, useContext } from 'react';
import { Row, Col, Popconfirm, Space, Spin, Layout, message, Typography, Card, List, Button } from 'antd'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


import User from "../../../Hooks/Logged";
import usePermissions from "../../../Hooks/usePermissions";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { IconEye } from '../../Widgets/Iconos'

import ModalCatalogos from './ModalCatalogos.js'
import { ButtonDelete, ButtonEdit } from '../../Widgets/Buttons';
import useSearch from '../../../Hooks/useSearch';
import { getResponseError } from '../../Utils'

const { Content } = Layout;
const { Text } = Typography;
/**
 *
 *
 * @export
 * @class Catalogos
 * @extends {Component}
 * @description Vista de Project Manager
 */
class Catalogos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            catalogo_id: undefined,

            catalogos: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

            }
        }
    }

    componentDidMount() {
        this.props.setShowSearch(true)
        this.getCatalogos();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.search !== this.props.search) {
            this.getCatalogos({ page: 1 })
        }
    }
    componentWillUnmount() {
        this.props.setShowSearch(false)
    }


    /**
    * @memberof Catalogos
    * @method getCatalogos
    * @description Trae las catalogos 
    * 
    */
    getCatalogos = ({
        page = this.state.catalogos.page,
        limit = this.state.catalogos.limit,

        search = this.props.search

    } = this.state.catalogos) => {
        this.setState({ loading: true, catalogos: { ...this.state.catalogos, page, limit } })
        axios.get('/catalogos', {
            params: { page, limit, search }
        }).then(({ data }) => {
            this.setState({ catalogos: data })
        }).catch(error => {
            message.error(error.response?.data?.message)
        }).finally(() => this.setState({ loading: false }))
    }

    render() {

        const { catalogos } = this.state

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="admin-page-header"
                        title="Catalogos"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Catalogos sin registros" }}
                            dataSource={catalogos.data}
                            pagination={{
                                current: catalogos.page,
                                pageSize: catalogos.limit,
                                total: catalogos.total,
                                showSizeChanger: true,
                                position: 'bottom',
                                onChange: (page, limit) => {
                                    this.getCatalogos({ page, limit })
                                }
                            }}
                            header={<Row className="width-100 pr-1 pl-1 component-list-header" >
                                <Col xs={12} md={8}>
                                    <Text strong>Nombre</Text>
                                </Col>
                                <Col xs={12} md={8}>
                                    <Text strong>Descripción</Text>
                                </Col>
                                <Col xs={12} md={5}>
                                    <Text strong>Productos</Text>
                                </Col>
                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[6, 8]}>
                                            <Col xs={12} md={8}>
                                                <Text strong>{item.nombre}</Text>
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <Text ellipsis>{item.descripcion}</Text>
                                            </Col>
                                            <Col xs={12} md={5}>
                                                <Text ellipsis>{item.productos?.length}</Text>
                                            </Col>
                                            <Col xs={12} md={3} className="center">
                                                <Space>
                                                    <Button
                                                        type='primary' 
                                                        className='btn-primary' 
                                                        icon={<IconEye />} 
                                                        onClick={()=>this.props.navigate(`/admin/catalogos/${item._id}`)}
                                                    />
                                                    <ButtonEdit
                                                        disabled={!this.props.editarCatalogos}
                                                        title="Editar"
                                                        onClick={() => { this.setState({ modalVisible: true, catalogo_id: item._id }) }}
                                                    />
                                                    <ButtonDelete
                                                        disabled={!this.props.deleteCatalogos}
                                                        onConfirm={() => axios.delete('/catalogos', {
                                                            params: {
                                                                id: item._id
                                                            }
                                                        }).then(() => {
                                                            this.getCatalogos()
                                                            message.success('Catalogo eliminado!')
                                                        }).catch(e => message.error(getResponseError(e.response, 'Error al eliminar catalogo')))}
                                                        title="Eliminar"
                                                    />


                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />


                    </Content>

                    <FloatingButton title="New register" onClick={() => this.setState({ modalVisible: true })} />


                </Spin>
                <ModalCatalogos
                    visible={this.state.modalVisible}
                    onClose={() => {
                        this.setState({ modalVisible: false, catalogo_id: undefined })
                        this.getCatalogos()
                    }}
                    catalogo_id={this.state.catalogo_id}
                />
            </>
        )

    }
}

export default function (props) {

    const { search, setShow } = useSearch()
    const user = useContext(User);

    const permisos = usePermissions(user?.rol_id?.permisos, {
        editarCatalogos: ["catalogos", "edit"],
        deleteCatalogos: ["catalogos", "delete"],
        createCatalogos: ["catalogos", "create"]

    });

    return <Catalogos {...props} search={search} setShowSearch={setShow} navigate={useNavigate()} {...permisos} />

}