import { Breadcrumb, Col, Layout, Row, Tabs, Typography, message } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//componentes
import usePermissions from "../../../Hooks/usePermissions";
import { CardSimpleInfo } from '../../Widgets/Cards';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import Productos from './Tabs/Productos';
import { PaperClipOutlined } from '@ant-design/icons';


const { Content } = Layout;
const { Title, Text, Link, Paragraph } = Typography
const moment = require('moment')
moment.locale('es');



/**
 *
 *
 * @export
 * @class CatalogoDetalles
 * @extends {Component}
 * @description Vista donde se muestra la informacion de un catalogo
 */
class CatalogoDetalles extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            catalogo_id: this.props.params.catalogo_id,
            catalogo: {

            },
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.params.catalogo_id) {
            this.getCatalogo()
        }
    }


    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getCatalogo
     * @description Obtiene informacion del Catalogo
     */
    getCatalogo = () => {
        this.setState({ loading: true })
        axios.get('/catalogo/' + this.state.catalogo_id, )
            .then(response => {
                this.setState({
                    catalogo: response.data
                })

            }).catch(error => {
                console.log("error", error);
                message.error("Error al obtener la informacion del catálogo ")
            }).finally(() => this.setState({ loading: false }))
    }


    render() {

        const { catalogo } = this.state

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<Breadcrumb
                        className="breadcrumb-header"
                        items={[
                            {
                                title: "Catálogos",
                                onClick: () => this.props.navigate('/admin/catalogos'),
                                className: "link"
                            },
                            {
                                title: ` ${catalogo?.nombre}`,
                            },
                        ]}
                    />}
                >
                </PageHeader>
                <Content className="pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <CardSimpleInfo
                                title="Catálogo"
                                content={
                                    <Row className="mt-1">
                                        <Col span={24}>
                                            <Text>Creado el {moment(catalogo.createdAt).format("MM/DD/YYYY")}</Text>
                                        </Col>
                                        <Col span={24}>
                                            <Text>{catalogo.nombre}</Text>
                                        </Col>
                                    </Row>
                                }
                            />
                        </Col>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <CardSimpleInfo
                                title="Descripción"
                                content={
                                    <Row className="mt-1">
                                        <Col span={24}>
                                            <Paragraph>
                                                {catalogo.descripcion}
                                            </Paragraph>
                                        </Col>
                                    </Row>
                                }
                            />
                        </Col>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <CardSimpleInfo
                                title="Archivo"
                                content={
                                    <Row className="mt-1">
                                        {
                                            catalogo.archivo && (
                                                <Link href={axios.defaults.baseURL + "/catalogo/" + catalogo._id + "?archivo=true&Authorization=" + sessionStorage.getItem("token")} target="_blank">
                                                    <PaperClipOutlined/>
                                                    {catalogo.archivo.name}
                                                </Link>
                                            )
                                        }
                                    </Row>
                                }
                            />
                        </Col>
                    </Row>

                    <Tabs
                        defaultActiveKey="productos"
                        destroyInactiveTabPane={true}
                        items={[
                            {
                                key: 'productos',
                                label: 'Productos',
                                children: <Productos getData={() => this.getCatalogo()} catalogo_id={this.props.params.catalogo_id} />,
                            }
                           
                        ]}
                    />

                </Content>
            </>
        )
    }
}


export default function (props) {

    const params = useParams()
    const navigate = useNavigate()


    return <CatalogoDetalles  {...props} params={params} navigate={navigate}
        {...usePermissions(null, {
            editarCatalogo: ["catalogos", "edit"],
            deleteCatalogo: ["catalogos", "delete"],
            createCatalogo: ["catalogos", "create"],
        })}
    />
}