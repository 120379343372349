import React from 'react'
import { Route, Routes } from "react-router-dom";

import Sizes from '../../Components/Admin/Sizes/Sizes';

/**
 * 
 * @export
 * @function RouterSizes
 * @description Router for Sizes routes 
 */
function RouterSizes(props) {
  return (
    <Routes>
      <Route path="" element={<Sizes {...props} />} />
    </Routes>
  )
}

export default RouterSizes