import React from 'react';
import { Route, Routes } from "react-router-dom";
import Catalogos from '../../Components/Admin/Catalogos/Catalogos';
import DetalleCatalogo from '../../Components/Admin/Catalogos/DetalleCatalogo';



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCatalogos(props) {

    return (
        <Routes>
            <Route path="" element={<Catalogos  {...props}  />} />
            <Route path="/:catalogo_id" element={<DetalleCatalogo  {...props}/>} />
        </Routes>
    )
}

export default RouterCatalogos;