import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin, Divider } from 'antd';

import axios from "axios"
import { getResponseError } from "../../Utils";
import SelectProducto from "../../Widgets/Inputs/SelectProducto";
import { ImagesUploader } from "../../Widgets/Uploaders";
import { toHaveStyle } from "@testing-library/jest-dom/matchers";

const { Title } = Typography;
const { Option } = Select;


class ModalDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        if(this.props.detalle_id) this.get()
    }

    get = () => {
        this.setState({loading: true})
        axios.get('/ventas/detalle/' + this.props.detalle_id)
            .then(({data}) => {
                this.formRef?.current?.setFieldsValue({
                    ...data,
                    imagenes: data.imagenes ? data.imagenes.map(f => ({
                        uid: f.file,
                        name: f.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/ventas/detalle/" + data._id + "?imagen=" + f.file + "&Authorization=" + sessionStorage.getItem("token")
                    })) : null,
                    producto_id: data.producto_id ? ({
                        value: data.producto_id._id,
                        label: `${data.producto_id.nombre} | ${data.producto_id.modelo} | ${data.producto_id.marca}`
                    }) : null
                })
            })
            .catch(error => {
                console.log(error)
                message.error("Error al cargar los detalles")
            })
            .finally(() => {
                this.setState({loading: false})
            })
    }


    /**
     * @memberof ModalDetalle
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        if(values.producto_id?.value) values.producto_id = values.producto_id.value
        values.venta_id = this.props.venta_id

        console.log(values)

        const formData = new FormData()
        formData.appendMultiple({
            ...values,
            detalle_id: this.props.detalle_id,
            imagenes: undefined
        })
        

        if (values.imagenes) {
            values.imagenes.forEach(img => {
                if (!img.fromDB) formData.append('imagenes', img, img.name)
            })
        }

        if(this.props.detalle_id){
            this.update(formData)
        }else{
            console.log("Error")
            this.save(formData)
        }
    }

    save = (values) => {
        this.setState({loading: true})
        axios.post('ventas/detalles', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(() => {
            message.success("Detalle creado")
            this.props.onClose()
        }).catch(error => {
            message.error("Error al crear detalle")
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    update = (values) => {
        this.setState({loading: true})
        axios.put('ventas/detalles', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        }).then(() => {
            message.success("Detalle actualizado")
            this.props.onClose()
        }).catch(error => {
            message.error("Error al actualizar detalle")
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {

        return (
            <Form
                layout="vertical"
                name="form-producto-venta"
                id="form-producto-venta"
                ref={this.formRef}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Modelo"
                                name="modelo"
                                rules={[{
                                    required: true,
                                    message: "Ingrese el modelo"
                                }]}
                            >
                                <Input placeholder="Modelo"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Marca"
                                name="marca"
                            >
                                <Input placeholder="Marca"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Unidad de Medida"
                                name="unidad"
                            >
                                <Input placeholder="Unidad de Medida"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                            >
                                <Input.TextArea rows={3} placeholder="Marca"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                name="imagenes"
                                label="Imagenes"
                            >
                                <ImagesUploader
                                    listType="picture-card"
                                    showUploadList={true}
                                    imageCrop={true}
                                    onRemove={({ name, fromDB }) => {
                                        if (fromDB) axios.put(`/ventas/detalles`, {
                                            id: this.props.detalle_id,
                                            delete_image: name
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Divider>Vinculación de Producto</Divider>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[{
                                    required: true,
                                    message: "Ingrese la cantidad"
                                }]}
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0.01} 
                                    placeholder="Cantidad"
                                    onChange={(e) => {this.setState({cantidad: e})}}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                                rules={[{
                                    required: true,
                                    message: "Seleccione el producto"
                                }]}
                            >
                                <SelectProducto params={{
                                    inventario: this.state.cantidad || this.props.cantidad
                                }}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, venta_id, detalle_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={detalle_id ? "Editar Detalle" : "Crear Detalle"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-producto-venta', key: 'submit', htmlType: 'submit' }}
    >
        <ModalDetalle {...props} />
    </Modal>

}