import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, InputNumber, Select, message, Upload, Checkbox, Spin } from 'antd';

import axios from "axios"
import { getResponseError } from "../../Utils";
import SelectProducto from "../../Widgets/Inputs/SelectProducto";

const { Title } = Typography;
const { Option } = Select;


class ModalVincular extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    formRef = React.createRef();

    componentDidMount() {
        
    }


    /**
     * @memberof ModalVincular
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        if(values.producto_id?.value) values.producto_id = values.producto_id.value

        this.setState({loading: true})
        axios.put('/catalogos/vincular', {
            ...values,
            catalogo_id: this.props.catalogo_id
        }).then(response => {
            message.success('Producto Vinculado')
            this.props.onClose()
        }).catch(error => {
            message.error(getResponseError(error.response, 'Error al vincular producto.'))
            console.log(error)
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {

        return (
            <Form
                layout="vertical"
                name="form-catalogo"
                id="form-catalogo"
                ref={this.formRef}
                onFinish={this.onFinish}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                                rules={[{
                                    required: true,
                                    message: "Seleccione el producto"
                                }]}
                            >
                                <SelectProducto
                                    params={{
                                        sin_catalogo: true
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, catalogo_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={"Vincular Producto con el Catálogo"}
        destroyOnClose={true}
        cancelText="Cancelar"
        okText="Guardar"
        okButtonProps={{ form: 'form-catalogo', key: 'submit', htmlType: 'submit' }}
    >
        <ModalVincular {...props} />
    </Modal>

}