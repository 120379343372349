import React from 'react'
import { Route, Routes } from "react-router-dom";
import { Layout, Button } from 'antd';

// Components
import ThemeEdit from '../Components/ThemeEdit/index';
import Login from '../Components/Auth/Login';
import Recovery from '../Components/Auth/Recovery';
import UpdatePassword from '../Components/Auth/UpdatePassword';
import Register from '../Components/Auth/Register';

import LandingPage from '../Components/Public/LandingPage';
import ProductoDetalle from '../Components/Public/ProductoDetalle';


import { SetCarrito, Carrito } from '../Hooks/Carrito';
import Landing from '../Components/Public/Landing';

/**
 * 
 * @export
 * @function PublicRoutes
 * @description Router for handling all public routes
 */
function PublicRoutes() {
  let [carrito, setCarrito] = React.useState([{
    tax:0,
    subtotal:0
  }]);

  return (
    <Carrito.Provider value={carrito}>
      <SetCarrito.Provider value={setCarrito}>
        <Layout>
          <Routes>
            {/* <Route path='/' element={<LandingPage />} /> */}
            <Route path='/' element={<Landing/>} />
            <Route path='/product/detail/:id' element={<ProductoDetalle />} />
            <Route path='theme' element={<ThemeEdit />} />
            <Route path='login' element={<Login />} />
            <Route path='password/recovery' element={<Recovery />} />
            <Route path='recovery/:token' element={<UpdatePassword />} />
          </Routes>
        </Layout>
      </SetCarrito.Provider>
    </Carrito.Provider>
  )
}

export default PublicRoutes;