import { CloseOutlined, WarningOutlined, PlusOutlined, MinusOutlined, SwapOutlined, StopOutlined, } from "@ant-design/icons"
import { Typography, Tag } from 'antd'

import '../Styles/Utils.css'


/**
 * @param {*} estatus
 * @description Renderiza el Tag con el estatus de la inversion
 */
const renderEstatusCliente = ({estatus = 0}) => {

    let steps = {
        0: <Tag className="tag-estatus cancelado">Cancelado</Tag>,
        1: <Tag className="tag-estatus registrado">Registrado</Tag>,
        2: <Tag className="tag-estatus contactado">Contactado</Tag>,
        3: <Tag className="tag-estatus compro">Otro</Tag>,
        4: <Tag className="tag-estatus pagado">Pagado</Tag>,

    }

    return estatus != undefined ? steps[estatus] : 'N/A'

}


/**
 * 
 * @param {*} response 
 * @param {String} si_falla_text 
 * @returns {String}
 */
const getResponseError = (response, si_falla_text) => {

    if (typeof (response?.data) === "string")
        return response.data

    if (typeof (response?.data.message) === "string")
        return response.data.message

    if (typeof (response?.data.message.data) === "string")
        return response.data.message.data

    return si_falla_text;
}

const renderImage = (id, images,model) => {
    if (images && images.length > 0)
        return `${process.env.REACT_APP_WEB_SERVICE}/${model}/${id}/?imagen=${images[0]?.file}`
    return `${process.env.REACT_APP_WEB_SERVICE}/images/default.jpg`
}


const moneyFormat = (value = 0) => {
    const money = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
    })

    return money.format(value)
}

export {
    renderEstatusCliente,
    getResponseError,
    renderImage,
    moneyFormat
}