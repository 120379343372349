import { Button, Card, Carousel, Col, Divider, Drawer, Layout, Rate, Row, Statistic, Typography } from 'antd';
import React, { Component, createRef } from 'react';
import '../../Styles/Modules/Landing/landing.scss';

// Components
import { ArrowRightOutlined, MenuOutlined, PhoneFilled } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import CustomAvatar from '../Widgets/Avatar/Avatar';

const { Title, Text, Paragraph } = Typography
const { Header, Content, Footer } = Layout

/**
 * 
 * @class Landing
 * @extends {Component}
 * @description Componente Landing page
 */
class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    }
  }

  carouselHeader = createRef(null)

  componentDidMount(){
    // Video
    const video = document.getElementById('header-bg-video')
    const button = document.getElementById('header-bg-button')

    button.addEventListener('click', this.toggleVideo(button, video))
    video.addEventListener('click', this.toggleVideo(button, video))
  }

  componentWillUnmount(){
    const video = document.getElementById('header-bg-video')
    const button = document.getElementById('header-bg-button')

    button.removeEventListener('click', this.toggleVideo(button, video))
    video.removeEventListener('click', this.toggleVideo(button, video))
  }

  toggleVideo = (btn, video) => {
    return () => {
      console.log(btn.classList, video.paused)
      btn.classList.toggle('hide')
      video.paused ? video.play() : video.pause()
    }
  }

  render() {

    const carouselSettings = {
      infinite: false,
      slidesToShow: 3,
      responsive: [{
          breakpoint: 1300,
          settings: {
              slidesToShow: 2
          }
      }, {
          breakpoint: 900,
          settings: {
              slidesToShow: 1
          }
      }, {
          breakpoint: 650,
          settings: {
              slidesToShow: 1
          }
      }]
    }

    const industrias = [{
      name: 'Industrial',
      icon: 'industrial',
      desc: 'Proporcionamos soluciones avanzadas para sistemas de automatización y maquinaria en procesos industriales.'
    },{
      name: 'Agrícola',
      icon: 'agricola',
      desc: 'Ofrecemos equipos y sistemas hidráulicos que optimizan las operaciones agrícolas, desde la irrigación hasta la maquinaria de cosecha.'
    },{
      name: 'Moviles',
      icon: 'moviles',
      desc: 'Suministramos componentes y sistemas para vehículos de transporte, mejorando la logística y mantenimiento de flotas.'
    },{
      name: 'Forestal',
      icon: 'forestal',
      desc: 'Brindamos soluciones en maquinaria y equipos para la gestión forestal, aumentando la eficiencia en la explotación de recursos madereros.'
    },{
      name: 'Petroleo y Gas',
      icon: 'petroleo-gas',
      desc: 'Proveemos tecnología y componentes para la extracción y procesamiento en la industria del petróleo y gas.'
    },{
      name: 'Navales',
      icon: 'navales',
      desc: 'Ofrecemos soluciones para la industria naval, incluyendo sistemas hidráulicos para barcos y equipos de mantenimiento marítimo.'
    },{
      name: 'Minería',
      icon: 'mineria',
      desc: 'Suministramos equipos robustos y confiables para la minería, facilitando la extracción y el procesamiento de minerales.'
    },{
      name: 'Energia',
      icon: 'energia',
      desc: 'Proporcionamos componentes esenciales para la generación y distribución de energía, incluyendo el sector de energías renovables.'
    }]

    const clientes = [{
      avatar: '',
      quote: 'Were super friendly and happy to answer all my questions. I highly recommend and use them again for any other that I might have.',
      name: 'Sauqi Arief',
      job: 'CEO of Mabar',
      rate: 4.5
    },{
      avatar: '',
      quote: 'Were super friendly and happy to answer all my questions. I highly recommend and use them again for any other that I might have.',
      name: 'Emilia Tadashi',
      job: 'Housewife',
      rate: 3.0
    },{
      avatar: '',
      quote: 'Were super friendly and happy to answer all my questions. I highly recommend and use them again for any other that I might have.',
      name: 'Faiza Mubarak',
      job: 'Success Man',
      rate: 4.5
    }]

    return(
      <>
        <Layout className="landing">
        <header className='landing-header header'>
          <section className='header-bg'>
            <div className='bg-carousel'>
              <Carousel className="carousel" ref={this.carouselHeader}>
                 <section className='carousel-slide'>
                    <Text className='slide-subtitle'>
                      ESPECIALISTAS EN MATERIAL HIDRAULICO
                    </Text>
                    <Title level={1} className='slide-title'>
                      Suministro de Articulos Hidráulicos, Oleo Hidráulicos para la industria en General e Hidrocarburos
                    </Title>
                    <Text className='slide-description'>
                      Ademas de Lubricantes, equipo para gasolineras, ¡Y más!
                    </Text>
                    <div className='slide-links'>
                      <Link to="#" className='slide-link'>
                        Obtener cotizacion
                      </Link>
                      <Link to="#" className='slide-link'>
                        Sobre Nosotros
                      </Link>
                    </div>
                 </section>
               </Carousel>
            </div>
            <div className='bg-video-wrapper'>
              <video className="bg-video" loop={true} id="header-bg-video">
                <source src='/video/placeholder.mp4' type="video/mp4"/>
              </video>
              <button id="header-bg-button" className='bg-button'>
                <div>
                  <img src="/img/landing/play.svg"/>
                </div>
              </button>
            </div>
          </section>
          <nav className='header-nav'>
            <Row gutter={[36, 36]} className='nav' align={"middle"}> 
              <Col xs={16} lg={5} xl={5}>
                <img src="/img/landing/logo.svg" className='nav-logo'/>
              </Col>
              <Col xs={0} lg={13} xl={14} className='nav-links'>
                <Link to={"#"} className='nav-link'>Inicio</Link>
                <Link to={"#"} className='nav-link'>Nosotros</Link>
                <Link to={"#"} className='nav-link'>Servicios</Link>
                <Link to={"#"} className='nav-link'>Productos</Link>
                <Link to={"#"} className='nav-link'>Contacto</Link>
                <Link to={"#"} className='nav-link'> <PhoneFilled/> 026-123-456</Link>
              </Col>
              <Col xs={0} md={5} lg={6} xl={5}>
                <Link to="#" className='nav-quote'>
                  Obtener una cotizacion
                  <ArrowRightOutlined/>
                </Link>
              </Col>
              <Col xs={8} md={3} className='nav-menu'>
                <MenuOutlined
                   onClick={() => this.setState({menuOpen: true})}
                />
              </Col>
            </Row>
          </nav>
        </header>
        <main className='landing-main main'>
          <section className='main-experience'>
            <Row gutter={[12, 12]} className='experience'>
              <Col xs={24} lg={7} className='experience-data'>
                <Text className='experience-number'>400+</Text>
                <Text className='experience-type'>Productos</Text>
                <Text className='experience-subtype'>Especializados</Text>
              </Col>
              <Col xs={12} lg={1} className='experience-div'>
                <Divider className='div-vertical' type="vertical"/>
                <Divider className='div-horizontal' type="horizontal"/>
              </Col>
              <Col xs={24} lg={8} className='experience-data'>
                <Text className='experience-number'>200+</Text>
                <Text className='experience-type'>Ventas</Text>
                <Text className='experience-subtype'>de Producto</Text>
              </Col>
              <Col xs={12} lg={1} className='experience-div'>
                <Divider className='div-vertical' type="vertical"/>
                <Divider className='div-horizontal' type="horizontal"/>
              </Col>
              <Col xs={24} lg={7} className='experience-data'>
                <Text className='experience-number'>100+</Text>
                <Text className='experience-type'>Años</Text>
                <Text className='experience-subtype'>de Experiencia</Text>
              </Col>
            </Row>
          </section>
          <section className="main-wwd">
            <Row className='wwd-bg'>
              <Col xs={24} lg={8}>
              </Col>
              <Col xs={24} lg={16} className='bg-orange'>
              </Col>
            </Row>
            <Row className='wwd'>
              <Col xs={24} lg={11} xl={12} className="wwd-image-wrapper">
                <img src="/img/landing/wwd.png" className='wwd-image'/>
              </Col>
              <Col xs={24} lg={13} xl={12} className="wwd-content">
                <Text className='wwd-subtitle'>WHAT WE DO</Text>
                <Title level={2} className='wwd-title'>Mejores herramientas, mejor calidad.</Title>
                <Paragraph>
                  Nuestras diversas sucursales y proveedores nos permiten ofrecer una rápida respuesta y productos hidráulicos y técnicos de alta calidad a precios competetivivos.
                </Paragraph>
                <blockquote>
                Brindaremos informacion sobre cómo utilizar nuestros prdouctos para permitir uso uso e implemetación en sus plataformas, maquinarias y establecimientos.
                </blockquote>
                <Paragraph>
                Procuramos que los productos tengan la logistica y asistencia para la entrega a tiempo.
                </Paragraph>
              </Col>
            </Row>
          </section>
          <section className='main-about'>
            <div className='about-header'>
              <Text className='about-subtitle'>NOSOTROS</Text>
              <Title className='about-title' level={2}>Alta Calidad en Productos Industriales</Title>
              <Paragraph className='about-description'>
                DICOFANSA ofrece servicios de alta calidad en instalaciones Oleo-Hidráulicas e Hidráulicas, sistemas de lubricación, y manejo de Hidrocarburos. Somos líderes en proporcionar soluciones costo-efectivas para la Industria Siderúrgica, Minera, Cementera, Papelera, Textil, Automotriz, y más. Además, somos proveedores clave de productos y repuestos
              </Paragraph>
            </div>
            <Row className='about' gutter={[30, 30]} justify={"center"}>
              {
                industrias.map(({name, icon, desc}, i) => (
                  <Col xs={24} sm={12} lg={8} xl={6} key={`about-${icon}-${i}`}>
                    <div className='about-card'>
                      <Text className='card-title'>{name}</Text>
                      <img className='card-icon' src={`/img/landing/industrias/${icon}.svg`}/>
                      <Paragraph className='card-desc'>
                        {desc}
                      </Paragraph>
                    </div>
                  </Col>
                ))
              }
            </Row>
          </section>
           <section className='main-reviews'>
            <div className='reviews-header'>
              <Text className='reviews-subtitle'>CLIENTES SATISFECHOS</Text>
              <Title className='reviews-title' level={2}>¿Qué dicen nuestros clientes?</Title>
              <Paragraph className='reviews-description'>
               Estos son los comentarios que nos han dicho nuestros clientes.
              </Paragraph>
            </div>
            <div className='reviews' gutter={[30, 30]}>
              <Carousel className='reviews-carousel' {...carouselSettings}>
                {
                  clientes.map(({name, quote, avatar, rate, job}, i) => (
                    <Card className="reviews-card" key={`review-${i}`}>
                      <CustomAvatar
                        className="card-avatar"
                        size={64}
                        src={avatar}
                      />
                      <Paragraph ellipsis={{rows: 3}} className='card-quote'>
                        "{quote}"
                      </Paragraph>
                      <Divider className='card-div'/>
                      <Row>
                        <Col span={12}>
                          <Text className='card-name'>{name}</Text><br/>
                          <Text className='card-job'>{job}</Text>
                        </Col>
                        <Col span={12} className='card-rating'>
                          <Statistic
                            className='card-value'
                            value={rate}
                            precision={1}
                          />
                          <Rate className='card-rate' disabled allowHalf value={rate}/>
                        </Col>
                      </Row>
                    </Card>
                  ))
                }
              </Carousel>
            </div>
          </section>
          <section className='main-cta'>
              <Row gutter={[24, 24]} className='cta' align={"middle"}>
                <Col xs={24} md={12}>
                  <Text className='cta-title'>¿Quieres más información?</Text> <br/>
                  <Text className='cta-subtitle'>Contactanos a través de nuestra información</Text>
                </Col>
                <Col xs={24} md={12} className='cta-right'>
                  <Link to={"#"} className='cta-button'>
                    Contact Us
                  </Link>
                </Col>
              </Row>
          </section>
        </main>
        <footer className='footer'>
          <Row className='footer-map' gutter={[40, 40]}>
              <Col xs={24} lg={6} className='map-about'>
                <img src="/img/landing/logo.svg" className='about-logo'/>
                <Paragraph className='about-description'>
                  The process of adding structures to areas of land, also known as real property sites.
                </Paragraph>
                <nav className='about-socials'>
                  <a href="#" target='_blank'>
                    <img src="/img/landing/social/facebook.svg"/>
                  </a>
                  <a href="#" target='_blank'>
                    <img src="/img/landing/social/insta.svg"/>
                  </a>
                  <a href="#" target='_blank'>
                    <img src="/img/landing/social/x.svg"/>
                  </a>
                  <a href="#" target='_blank'>
                    <img src="/img/landing/social/yt.svg"/>
                  </a>
                </nav>
              </Col>
              <Col xs={24} sm={12} md={6} lg={4} className='map-links'>
                <Text className='map-title'>Links</Text>
                <nav className='links-list'>
                  <Link to="">Home</Link>
                  <Link to="">Company</Link>
                  <Link to="">Projects</Link>
                  <Link to="">Contact</Link>
                  <Link to="">Call</Link>
                </nav>
              </Col>
              <Col xs={24} sm={12} md={6} lg={4} className='map-links'>
                <Text className='map-title'>About</Text>
                <nav className='links-list'>
                  <Link to="">Partners</Link>
                  <Link to="">Careers</Link>
                  <Link to="">Press</Link>
                  <Link to="">Community</Link>
                </nav>
              </Col>
              <Col xs={24} sm={12} md={6} lg={4} className='map-links'>
                <Text className='map-title'>Company</Text>
                <nav className='links-list'>
                  <Link to="">Events</Link>
                  <Link to="">Blog</Link>
                  <Link to="">Forum</Link>
                  <Link to="">Podcast</Link>
                  <Link to="">Telegram</Link>
                </nav>
              </Col>
              <Col xs={24} sm={12} md={6} lg={6} className='map-offices'>
                <Text className='map-title'>Sucursales</Text>
                <div className='offices-list'>
                  <Paragraph>
                    Indonesia <br/>
                    Jl. Griya Permata Hijau no D1 Purwomartani Kalasan 543881
                  </Paragraph>
                  <Paragraph>
                    Singapore <br/>
                    35 Mandalay Road 13–37 Mandalay Towers Singapore 308215
                  </Paragraph>
                </div>
              </Col>
          </Row>
        </footer>
      </Layout>
      <Drawer
        open={this.state.menuOpen}
        onClose={() => this.setState({menuOpen: false})}
        className='landing-menu'
        placement='left'
        title={null}
        closeIcon={null}
        width={220}
      >
         <nav className='menu-links'>
            <Link to={"#"} className='menu-link'>Inicio</Link>
            <Link to={"#"} className='menu-link'>Nosotros</Link>
            <Link to={"#"} className='menu-link'>Servicios</Link>
            <Link to={"#"} className='menu-link'>Productos</Link>
            <Link to={"#"} className='menu-link'>Contacto</Link>
            <Link to={"#"} className='menu-link'> <PhoneFilled/> 026-123-456</Link>
          </nav>
          <Link to="#" className='menu-quote'>
            Obtener una cotizacion
            <ArrowRightOutlined/>
          </Link>
      </Drawer>
      </>
      
    )
  }
}

export default function (props) {

  return <Landing {...props} />
}