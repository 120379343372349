import React, { useState, useEffect } from 'react';
import { Col, Row, Select, Space, message, Typography } from 'antd'
import axios from "axios"

const { Option } = Select 
const { Text } = Typography

export const DireccionLabel = ({direccion, codigo_postal, nombre, ciudad, estado, pais}) => {
    return (
        <Row style={{margin: 0}}>
            <Col span={24}>
                <Text strong ellipsis>{nombre}</Text>
            </Col>
            <Col span={24}>
                <Space direction='vertical' size={4}>
                    <Text style={{fontSize: 11}}>{direccion ?? ""}, {codigo_postal ?? ""}</Text>
                    <Text style={{fontSize: 11}}>{ciudad ?? ""} {estado ?? ""}, {pais ?? ""}</Text>
                </Space>
            </Col>
        </Row>
    )
}

/**
 * @const SelectDirecciones
 * @description Select para las direcciones registrados en el sistema
 */
const SelectDirecciones = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione una dirección", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        mode = null,
        labelInValue
    } = props

    const [ direcciones, setDirecciones ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getDirecciones
     * @description Obitiene los usaurios
     */
    const getDirecciones = ({
        page, 
        limit, 
        search, 
    } = direcciones) => {

        axios.get('/direcciones', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setDirecciones(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error al obtener las direcciones')
        })
    }

    //componentDidMount
    useEffect(() => {
        getDirecciones()
    }, [params?.cliente_id])


    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            optionLabelProp='label'
            mode={mode}
            onSearch={(search)=> getDirecciones({search})}
            onSelect={(cliente)=> {
                if(mode === null && !labelInValue){
                    onChange(cliente.value)
                    onSelect(cliente.value)
                }
                if(mode === null && labelInValue){
                    onChange(cliente)
                    onSelect(cliente)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple" && !labelInValue){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }

                if(mode === "multiple" && labelInValue){
                    onChange(values)
                    onSelect(values)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
        >
            {
                direcciones?.data?.map(({ _id, ...direccion}) => <Option value={_id} key={_id} label={direccion.nombre}>
                    <DireccionLabel 
                        {...direccion}
                    />
                </Option>)
            }
        </Select>
    );
}


export default SelectDirecciones;