import React, { Component } from "react";
import { Row, Col, Modal, Form, Input, message, Spin, Select } from 'antd';

import { AvatarLoader } from "../../Widgets/Uploaders";
import axios from "axios"
import { getResponseError } from "../../Utils";
const { Option } = Select;



/**
 * @class ModalUsuarios
 * @description Modal para el CRUD de Usuarios
 */
class ModalUsuarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            roles: {
                data: [],
                limit: 10,
                page: 1,

                total: 0,
                pages: 0,
            },
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getRoles()
        if (this.props.usuario_id)
            this.get()

    }

    formRef = React.createRef();

    /**
    * @method addUser
    * @description Añade un nuevo registro de usuario
    */
    get = () => {
        this.setState({ loading: true })
        axios.get('/usuarios/' + this.props.usuario_id)
            .then(({ data }) => {
                console.log("data", data);
                this.formRef.current.setFieldsValue({
                    ...data,
                    avatar: data.avatar ? {
                        uid: data.avatar.file,
                        name: data.avatar.name,
                        status: 'done',
                        fromDB: true,
                        url: axios.defaults.baseURL + "/usuarios/" + data._id + "?avatar=" + data.avatar.file + "&Authorization=" + sessionStorage.getItem("token")
                    } : null,
                    apellido_paterno: data?.cliente_id?.apellido_paterno,
                    apellido_materno: data?.cliente_id?.apellido_materno,
                })
                this.setState({ cliente: data.cliente_id })
            }).catch(error => {
                message.error('Error al cargar la información del usuario')
            }).finally(() => this.setState({ loading: false }))
    }




    /**
         * @methodOf  Usuarios
         * @method getAreas
         *
         * @description Obtiene los roles
         * */
    getRoles = ({
        page = this.state.roles.page,
        limit = this.state.roles.limit,
        search = this.props.search
    } = this.state.roles) => {
        this.setState({ loading: true, roles: { ...this.state.roles, page, limit } })
        axios.get('/roles', {
            params: {
                page,
                limit,
                search
            }
        }).then(({ data }) => {
            this.setState({
                roles: { ...this.state.roles, ...data },
            });
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar la información de los roles')
        }).finally(() => {
            this.setState({ loading: false })
        })
    }

    /**
    * @method add
    * @description Añade un nuevo registro de usuario
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/usuarios', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('Usuario creado exitosamente')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al crear el usuario'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Añade un nuevo registro de usuario
    */
    update = (values) => {

        console.log('update', values)
        this.setState({ loading: true })
        axios.put('/usuarios', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(response => {
                message.success('Usuario actualizado exitosamente')
                this.props.onClose()
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error al actualizar al usuario'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {

        const formData = new FormData()

        formData.appendMultiple({
            ...values,
            id: this.props.usuario_id,
        })

        if (this.props.usuario_id) {
            this.update(formData)
        } else {
            this.add(formData)
        }
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-usuario"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                >
                    <Row justify="center" className="center">
                        <Col span={20}>
                            <Row justify={"center"}>
                                <Form.Item
                                    name="avatar"
                                >
                                    <AvatarLoader
                                        imageCrop={true}
                                        onRemove={() => {
                                            axios.put('/usuarios/', {
                                                id: this.props.usuario_id,
                                                delete_avatar: true
                                            })
                                        }}

                                    />
                                </Form.Item>
                            </Row>
                            <Form.Item
                                name="nombre"
                                label="Nombre"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese el nombre',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="apellidos"
                                label="Apellidos"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Ingrese los apellidos',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="E-mail"
                                rules={[
                                    {
                                        type: 'email',
                                        message: 'El email es invalido',
                                    },
                                    {
                                        required: true,
                                        message: 'Ingrese el email',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="telefono"
                                label="Teléfono"
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="rol_id"
                                label="Rol"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Seleccion el rol',
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    filterOption={false}
                                    onSearch={search => this.getRoles({ search, page: 1 })}
                                >
                                    {this.state.roles.data.map(rol => <Option key={rol._id} value={rol._id} rol={rol}>{rol.nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { visible = false, onClose = () => { }, usuario_id } = props

    return <Modal
        open={visible}
        onCancel={onClose}
        title={usuario_id ? "Crear Usuario" : "Editar Usuario"}
        closable={true}
        destroyOnClose={true}
        okText="Guardar"
        cancelText="Cancelar"
        zIndex={1000}
        okButtonProps={{ form: 'form-usuario', key: 'submit', htmlType: 'submit' }}
    >
        <ModalUsuarios  {...props} />
    </Modal>

}